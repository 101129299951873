<script lang="ts">
  import type { FacetValue } from "../facet-value";
  import type { Facet, FacetOptions } from "./type.ts";
  import { List } from "../facet-value-group";

  import type { ClassValue } from "svelte/elements";
  import { addNameAndFeatureTracking, isNotSelected, isSelected } from "./facetValues.ts";
  import { featureTrackingAttributes } from "../tracking";
  import SearchBox from "../input/SearchBox.svelte";

  interface Props extends Facet, FacetOptions {
    class?: ClassValue;
    scrollable?: boolean;
    searchable?: boolean;
  }

  let {
    name,
    values,
    popularValues = [],
    featureTracking,
    visibility,
    contentSnippet = defaultList,
    scrollable = true,
    searchable = false,
    ...rest
  }: Props = $props();

  const id = (featureTracking && `facet_${featureTracking.id}`) || "facet";

  /*                                                                                                    */
  const hidden = visibility === "hidden";

  let filtered: string[] = $state([]);
  let searchTerm: string = $state("");

  const selectedPopularValues = $derived(popularValues.filter(isSelected));
  const selectedValues = $derived(
    values
      .concat(selectedPopularValues)
      .filter(isSelected)
      .map(addNameAndFeatureTracking(id, name, 1)),
  );
  const unselectedPopularValues: FacetValue[] = $derived(
    searchTerm !== ""
      ? []
      : popularValues.filter(isNotSelected).map(addNameAndFeatureTracking(id, name, selectedValues.length + 1)),
  );
  const unselectedValues: FacetValue[] = $derived(
    searchTerm === ""
      ? values
          .filter(isNotSelected)
          .map(addNameAndFeatureTracking(id, name, unselectedPopularValues.length + selectedValues.length + 1))
          .filter((value: FacetValue) => !searchable || filtered.includes(value.title))
          .map((value: FacetValue) => ({ ...value, highlighted: searchTerm }))
      : popularValues
          .concat(values)
          .filter(isNotSelected)
          .map(addNameAndFeatureTracking(id, name, selectedValues.length + 1))
          .filter((value: FacetValue) => !searchable || filtered.includes(value.title))
          .map((value: FacetValue) => ({ ...value, highlighted: searchTerm })),
  );
</script>

{#snippet defaultList(values: FacetValue[])}
  <List {values}></List>
{/snippet}

<fieldset
  {id}
  {name}
  class={["hc_facet", rest.class]}
  data-facet-visibility={visibility}
  {...featureTrackingAttributes(featureTracking)}
  {hidden}
>
  {@render contentSnippet(selectedValues)}
  {#if searchable}
    <div class="hc_searchbox_wrapper">
      <SearchBox
        label="Nach Marke suchen"
        emptyMessage="Nichts gefunden – versuch’ einen anderen Begriff"
        items={values.concat(popularValues).map((value: FacetValue) => value.title)}
        bind:value={searchTerm}
        bind:filtered
      />
    </div>
  {/if}
  {#if unselectedPopularValues.length > 0}
    <div class={["hc_facet__scrollBox", { "hc_facet__scrollBox--scrollable": scrollable }]}>
      <span class="hc_facet__section_divider">BELIEBT</span>
      {@render contentSnippet(unselectedPopularValues)}
      <span class="hc_facet__section_divider">WEITERE</span>
      {@render contentSnippet(unselectedValues)}
    </div>
  {:else}
    <div class={["hc_facet__scrollBox", { "hc_facet__scrollBox--scrollable": scrollable }]}>
      {@render contentSnippet(unselectedValues)}
    </div>
  {/if}
</fieldset>

<style lang="scss">
  fieldset {
    min-width: 0;
  }

  .hc_searchbox_wrapper {
    margin: 16px 0;
  }

  .hc_facet__section_divider {
    font-family: "OttoSans", Arial, Helvetica, sans-serif;
    color: #777;
    font-size: 0.75rem;
    line-height: 1.33333333333;
  }

  .hc_facet__scrollBox {
    &--scrollable {
      max-height: var(--scroll-area-height, 365px);
      overflow-y: auto;
      margin-left: calc(-2 * var(--oc-semantic-focus-outline-offset));
      padding-left: calc(2 * var(--oc-semantic-focus-outline-offset));
    }
  }
</style>
