import { eventQBus } from "../types/EventQBus";
import { cloneElement, moveAllChildren } from "../util/Utils";
import type { FilterSectionLoadedEvent } from "../multifiltering/FilterTypes";
import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { updateWhatIfTracking } from "../tracking/WhatIfTracking";
import { SmartSorting } from "./SmartSorting";

const ID_SMART_SORTING_STATUS_QUO = "heureka_smartSorting--StatusQuo";

export class SmartSortingWhatIfTracking {
  private static readonly factory = HeurekaElementFactory.byId(ID_SMART_SORTING_STATUS_QUO, SmartSortingWhatIfTracking);

  /*               */
  constructor(
    private readonly elem: HTMLElement,
    private readonly matchedValues: HTMLInputElement[] = SmartSorting.findMatches(elem),
  ) {}

  /*                  */

  static template(rootElement?: ParentNode | null) {
    return SmartSortingWhatIfTracking.factory.pick(undefined, rootElement);
  }

  /*               */

  static register() {
    eventQBus.on("heureka.filterSection.loaded", SmartSortingWhatIfTracking.initAll);
  }

  static initAll(event: FilterSectionLoadedEvent, rootElement?: ParentNode) {
    SmartSortingWhatIfTracking.template(rootElement)?.init();
  }

  protected init() {
    const hasMatchingValues = this.relevantValues.length > 0;
    const sanFilterWhatIf = `e3387_${hasMatchingValues}`;
    updateWhatIfTracking(sanFilterWhatIf);
    return this;
  }

  static prepareAll(fragment: DocumentFragment) {
    SmartSortingWhatIfTracking.template(fragment)?.prepare();
  }

  private prepare() {
    const oldTemplate = SmartSortingWhatIfTracking.template();
    if (oldTemplate) {
      this.values = oldTemplate.values;
    }
  }

  /*                       */

  private get values(): HTMLElement {
    return this.elem;
  }

  private set values(source) {
    moveAllChildren(source, this.elem, true);
  }

  get relevantValues(): HTMLInputElement[] {
    return this.matchedValues.map(cloneElement);
  }
}
