<script module lang="ts">
  const totalNumberStars = 5;
  const starsMapping: Record<string, number> = {
    "ab einem Stern": 1,
    "ab 2 Sternen": 2,
    "ab 3 Sternen": 3,
    "ab 4 Sternen": 4,
    "5 Sterne": 5,
  };
</script>

<script lang="ts">
  import type { FacetValueLabel, FacetValueLabelOptions } from "./type";

  interface Props extends FacetValueLabel, FacetValueLabelOptions {}

  let { title, itemCount, fitContent = true, size = "100", titleSize, bold }: Props = $props();

  const numberStars: number = starsMapping[title] || 0;
</script>

{#snippet filledStar()}<oc-icon-v1 type="rating-filled" class="hc_rating__icon"></oc-icon-v1>{/snippet}

{#snippet emptyStar()}<oc-icon-v1 type="rating-empty" class="hc_rating__icon"></oc-icon-v1>{/snippet}

<span class={["hc_rating", `hc_rating--${size}`, { "hc_rating--grow": !fitContent }]} data-count={itemCount}
  ><span class={["hc_rating__title", `hc_rating__title--${titleSize || size}`, { "hc_rating__title--bold": bold }]}
    >{#each { length: numberStars } as _}
      {@render filledStar(_)}
    {/each}{#each { length: totalNumberStars - numberStars } as _}
      {@render emptyStar(_)}
    {/each}{#if numberStars < totalNumberStars}& mehr{/if}</span
  ></span
>

<style lang="scss">
  .hc_rating {
    max-width: 100%;
    display: flex;
    align-items: center;
    gap: var(--oc-semantic-spacing-50);
    overflow: hidden;
    font: var(--label-font);

    &--50 {
      --label-font: var(--oc-semantic-font-copy-50);
    }

    &--75 {
      --label-font: var(--oc-semantic-font-copy-75);
    }

    &--100 {
      --label-font: var(--oc-semantic-font-copy-100);
    }

    &--125 {
      --label-font: var(--oc-semantic-font-copy-125);
    }

    &--grow {
      justify-content: space-between;
      width: 100vw; /*                             */
    }

    &__icon {
      /*                                             */
      height: var(--icon-size);
      width: var(--icon-size);
      vertical-align: text-bottom;
      margin-right: var(--oc-semantic-spacing-12);
    }

    &__title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 0 1 min-content;
      font: var(--label-font);

      &--50 {
        --label-font: var(--oc-semantic-font-copy-50);
        --icon-size: 0.75rem;
      }

      &--75 {
        --label-font: var(--oc-semantic-font-copy-75);
        --icon-size: 1rem;
      }

      &--100 {
        --label-font: var(--oc-semantic-font-copy-100);
        --icon-size: 1.25rem;
      }

      &--125 {
        --label-font: var(--oc-semantic-font-copy-125);
        --icon-size: 1.5rem;
      }

      &--bold {
        font-weight: 700;
      }
    }

    &[data-count]::after {
      flex: 0 0 max-content;
      color: var(--oc-semantic-color-text-secondary);
    }

    &:not(&--grow)[data-count]::after {
      content: "(" attr(data-count) ")";
    }

    &--grow[data-count]::after {
      content: attr(data-count);
    }
  }
</style>
