import { HeurekaElementFactory } from "../util/HeurekaElementFactory";

const FACET_GROUP_CLASS = "find_aggregatedFacet";
const FACET_GROUP_ACTIVE_CLASS = `${FACET_GROUP_CLASS}--active`;

export class FacetGroup {
  static readonly factory = HeurekaElementFactory.byClass(FACET_GROUP_CLASS, FacetGroup);

  /*               */
  constructor(readonly facetGroup: HTMLElement) {}

  /*                  */

  static groupId(id: string, root?: ParentNode | null) {
    return FacetGroup.factory.pick(`.${FACET_GROUP_CLASS}--${id}`, root);
  }

  /*               */

  /*                       */

  get name() {
    const classList = this.facetGroup.classList;
    return Array.from(classList)
      .find((s) => s.startsWith("find_aggregatedFacet--"))
      ?.substring("find_aggregatedFacet--".length);
  }

  hasName(name: string) {
    return this.facetGroup.classList.contains(`find_aggregatedFacet--${name}`);
  }

  get active(): boolean {
    return this.facetGroup.classList.contains(FACET_GROUP_ACTIVE_CLASS);
  }

  set active(active) {
    this.facetGroup.classList.toggle(FACET_GROUP_ACTIVE_CLASS, active);
  }

  /**
 *
 *
 *
 *
 */
  toggleVisibility(force = this.active) {
    const wasActive = this.active;
    this.active = force;
    return wasActive !== this.active;
  }
}
