/**
 *
 *
 */
export default function inputDefault(node: HTMLInputElement, freezeDefault?: boolean) {
  switch (node.type) {
    case "radio":
    case "checkbox":
      node.defaultChecked = node.checked;
    /*            */
    case "text":
      if (freezeDefault) {
        /*                                                                                                      */
        //
        /*                                                                                                     */
        /*                                                                                                          */
        //
        /*                                                                 */
        Object.defineProperty(node, "defaultValue", {
          value: node.value,
          configurable: true,
          writable: false,
          enumerable: true,
        });
      } else {
        node.defaultValue = node.value;
      }
      return;
  }
}
