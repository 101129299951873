import type { FeatureTracking } from "./FeatureTracking";
import type { FeatureTrackingReference } from "./FeatureTrackingReference";

export type FeatureTrackingAttributes = {
  "data-ts-feature-name": string;
  "data-ts-feature-status"?: string;
  "data-ts-feature-parent-id"?: string;
  "data-ts-feature-position"?: string;
  "data-ts-feature-labels"?: string;
  "data-ts-feature-filter-method"?: string;
};

export type FeatureTrackingReferenceAttributes = {
  "data-ts-feature-ref": string;
  "data-ts-feature-labels"?: string;
};

export function featureTrackingAttributes(featureTracking: undefined): undefined;
export function featureTrackingAttributes(featureTracking: FeatureTracking): FeatureTrackingAttributes;
export function featureTrackingAttributes(featureTracking?: FeatureTracking): FeatureTrackingAttributes | undefined;
export function featureTrackingAttributes(featureTracking?: FeatureTracking): FeatureTrackingAttributes | undefined {
  /*                                          */
  if (featureTracking) {
    const { name, status, position, parentId, labels, method } = featureTracking;
    const featureLabels = labels ? JSON.stringify(labels) : undefined;
    /*                                                                           */
    return {
      "data-ts-feature-name": name,
      ...(status && { "data-ts-feature-status": status }),
      ...(parentId && { "data-ts-feature-parent-id": parentId }),
      ...(position && { "data-ts-feature-position": position }),
      ...(featureLabels && { "data-ts-feature-labels": featureLabels }),
      ...(method && { "data-ts-feature-filter-method": method }),
    };
  }
}

export function featureTrackingReferenceAttributes(featureTrackingReference: undefined): undefined;
export function featureTrackingReferenceAttributes(
  featureTrackingReference: FeatureTrackingReference,
): FeatureTrackingReferenceAttributes;
export function featureTrackingReferenceAttributes(
  featureTrackingReference?: FeatureTrackingReference,
): FeatureTrackingReferenceAttributes | undefined;
export function featureTrackingReferenceAttributes(
  featureTrackingReference?: FeatureTrackingReference,
): FeatureTrackingReferenceAttributes | undefined {
  /*                                          */
  if (featureTrackingReference) {
    const { ref, labels } = featureTrackingReference;
    const featureLabels = labels ? JSON.stringify(labels) : undefined;
    /*                                                                           */
    return {
      "data-ts-feature-ref": ref,
      ...(featureLabels && { "data-ts-feature-labels": featureLabels }),
    };
  }
}
