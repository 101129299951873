<script lang="ts">
  import type { FacetValue } from "../facet-value";
  import type { FacetValueGroup, FacetValueGroupOptions } from "./type.ts";
  import { Chip } from "../facet-value";

  interface Props extends FacetValueGroup, FacetValueGroupOptions {}

  let { values = [], title, item = chip }: Props = $props();
</script>

{#snippet chip(facetValue: FacetValue)}
  <Chip {...facetValue} />
{/snippet}

{#if values.length > 0}
  <div>
    {#if title}<h4>{title}</h4>{/if}
    <ul>
      {#each values as facetValue}
        <li>{@render item(facetValue)}</li>
      {/each}
    </ul>
  </div>
{/if}

<style lang="scss">
  h4 {
    margin-bottom: var(--oc-semantic-spacing-75);
    font: var(--oc-semantic-font-copy-75);
    text-transform: uppercase;
    color: var(--oc-semantic-color-text-secondary);
  }

  ul {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, var(--grid-cell-width, minmax(4rem, 1fr)));
    gap: var(--oc-semantic-spacing-50) var(--oc-semantic-spacing-50);

    --label-width: minmax(0, 100%);
  }
</style>
