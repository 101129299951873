<script lang="ts">
  import type { FacetValue } from "../facet-value";
  import type { Facet, FacetOptions } from "./type.ts";
  import { List } from "../facet-value-group";

  import type { ClassValue } from "svelte/elements";
  import { addNameAndFeatureTracking } from "./facetValues.ts";
  import { featureTrackingAttributes } from "../tracking";

  interface Props extends Facet, FacetOptions {
    class?: ClassValue;
  }

  let { name, values, featureTracking, visibility, contentSnippet = defaultList, ...rest }: Props = $props();

  const id = (featureTracking && `facet_${featureTracking.id}`) || "facet";

  /*                                                                                                    */
  const hidden = visibility === "hidden";

  const namedValues = $derived(values.map(addNameAndFeatureTracking(id, name, 1)));
</script>

{#snippet defaultList(values: FacetValue[])}
  <List {values}></List>
{/snippet}

<fieldset
  {id}
  {name}
  class={["hc_facet", rest.class]}
  data-facet-visibility={visibility}
  {...featureTrackingAttributes(featureTracking)}
  {hidden}
>
  {@render contentSnippet(namedValues)}
</fieldset>

<style lang="scss">
  fieldset {
    min-width: 0;
  }
</style>
