<script lang="ts">
  import Radio from "../input/RadioInput.svelte";
  import type { FacetValue, FacetValueOptions } from "./type";
  import { SingleSelect } from "../facet-value-input";
  import { Text } from "../facet-value-label";

  interface Props extends FacetValue, FacetValueOptions {}

  let { input = radio, children = text, ...rest }: Props = $props();
</script>

{#snippet radio()}
  <SingleSelect {...rest} />
{/snippet}

{#snippet text()}
  <Text size="125" {...rest} />
{/snippet}

<Radio class="hc_facetValue" fitContent={true} labelPlacement="right" {input} {children} />
