import { getElementById } from "../util/Utils";

export type DummyExperimentVariation = "StatusQuo" | "DummyVar1" | "DummyVar2";
export type PersonalizationSaveSizesExperimentVariation = "StatusQuo" | "SaveSizes";
export type RetailerOttoToggleExperimentVariation = "StatusQuo" | "Toggle";

export type Experiments = {
  [key: string]:
    | DummyExperimentVariation
    | PersonalizationSaveSizesExperimentVariation
    | RetailerOttoToggleExperimentVariation
    | undefined;
  d666?: DummyExperimentVariation;
  e3095?: PersonalizationSaveSizesExperimentVariation;
};

let experiments: Experiments;

export function initExperiments() {
  const data = getElementById("leafcutter_experiments")?.dataset;
  if (data) {
    experiments = {};
    for (const key in data) {
      const variation = data[key];
      if (variation) {
        (experiments as Experiments)[key.toLowerCase()] = variation as Experiments[keyof Experiments];
      }
    }
  }
}

/**
 *
 */
export function updateExperiment<T extends keyof Experiments>(experiment: T, variation: Experiments[T]) {
  experiments[experiment] = variation;
}

export function getActiveVariation<T extends keyof Experiments>(
  exp: T,
  defaultVariation?: Experiments[T],
): Experiments[T] | undefined {
  return experiments ? experiments[exp] || defaultVariation : defaultVariation;
}

export function isActiveVariation<T extends keyof Experiments>(exp: T, variation: Experiments[T]): boolean {
  return experiments ? experiments[exp] === variation : false;
}
