export type FeatureTracking = {
  labels: object;
  id: string;
  name: string;
  parentId?: string;
  position?: string;
  status?: string;
  method?: string;
};

export function removeStatusIf<T extends FeatureTracking | undefined>(condition: boolean, featureTracking: T): T {
  return condition && featureTracking ? { ...featureTracking, status: undefined } : featureTracking;
}
