import { eventQBus } from "../types/EventQBus";
import { filterItems, forEachElement, mapElements, mapItems } from "../util/Utils";
import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import type { FilterSectionLoadedEvent } from "../multifiltering/FilterTypes";

const OVERFLOW_BOX_CONTAINER_CLASS = "ts_heureka_overflowBoxContainer";

export class OverflowBoxContainer {
  private constructor(private readonly overflowBoxContainer: HTMLElement) {}

  private static create(elem: HTMLElement) {
    return new OverflowBoxContainer(elem);
  }

  public static allOverflowBoxContainers(root?: ParentNode | null) {
    return mapElements(".ts_heureka_overflowBoxContainer", OverflowBoxContainer.create, root || undefined);
  }

  public static forEachOverflowBoxContainer(
    callback: (overflowBoxContainer: OverflowBoxContainer, index: number) => void,
    rootElement?: ParentNode,
  ) {
    forEachElement(
      ".ts_heureka_overflowBoxContainer",
      (elem, index) => callback(OverflowBoxContainer.create(elem), index),
      rootElement,
    );
  }

  public static declareOverflowBoxContainer(elem: HTMLElement | EventTarget | null) {
    if (elem instanceof HTMLElement) {
      return elem.classList.contains(OVERFLOW_BOX_CONTAINER_CLASS) ? OverflowBoxContainer.create(elem) : undefined;
    }
  }

  public scrollLeft(scrollLeft: number) {
    const overflowBox = OverflowBox.factory.pick(undefined, this.overflowBoxContainer);
    if (overflowBox) {
      overflowBox.scrollLeft = scrollLeft;
    } else {
      this.overflowBoxContainer.dataset.scrollLeft = scrollLeft.toString();
    }
    return this;
  }

  public getScrollLeft() {
    return OverflowBox.factory.pick(undefined, this.overflowBoxContainer)?.scrollLeft || 0;
  }

  setContent(overflowBoxContent: HTMLElement) {
    overflowBoxContent.classList.add("heureka_overflowBox", "ts_heureka_overflowBox");
    mapItems(overflowBoxContent.children, (elem) => {
      elem.classList.add("heureka_overflowBox__item");
    });
    const { scrollLeft } = this.overflowBoxContainer.dataset;
    if (scrollLeft) {
      this.scrollLeft(parseInt(scrollLeft));
      delete this.overflowBoxContainer.dataset.scrollLeft;
    }
    return this;
  }

  selectAndSetContent(selector: string) {
    forEachElement(
      selector,
      (overflowBoxContainerContent) => {
        this.setContent(overflowBoxContainerContent);
      },
      this.overflowBoxContainer,
    );
    return this;
  }

  removeContent() {
    OverflowBox.factory.pick(undefined, this.overflowBoxContainer)?.removeContent();
  }

  remove() {
    this.removeContent();
    this.overflowBoxContainer.classList.remove(OVERFLOW_BOX_CONTAINER_CLASS);
  }
}

export class OverflowBox {
  public static factory = HeurekaElementFactory.byClass("ts_heureka_overflowBox", OverflowBox);

  constructor(
    private readonly overflowBox: HTMLElement,
    private readonly items: NodeListOf<HTMLElement> = OverflowBox.items(overflowBox),
    private readonly stickyItem: HTMLElement | null = OverflowBox.stickyItem(overflowBox),
  ) {}

  private static items(elem: HTMLElement) {
    return elem.querySelectorAll<HTMLElement>(".heureka_overflowBox__item:not(.heureka_overflowBox__item--sticky)");
  }

  private static stickyItem(elem: HTMLElement) {
    return elem.querySelector<HTMLElement>(".heureka_overflowBox__item--sticky");
  }

  /*               */

  static register() {
    eventQBus.on("heureka.filterSection.loaded", OverflowBox.initAll);
    eventQBus.on("heureka.filterSection.loadAborted", OverflowBox.initAll);
  }

  static initAll(event?: FilterSectionLoadedEvent, rootElement?: ParentNode) {
    OverflowBox.factory.forEach((box) => box.init(), rootElement);
  }

  protected init() {
    this.initializeCollapsedState();
    return this;
  }

  static prepareAll(fragment: DocumentFragment) {
    OverflowBox.factory.forEach((box) => box.prepare(), fragment);
  }

  private prepare() {
    const oldTemplate = OverflowBox.factory.pick(this.selector);
    if (oldTemplate) {
      this.scrollLeft = oldTemplate.scrollLeft;
      if (this.flexible) {
        this.largeMode = oldTemplate.largeMode;
      }
    }
  }

  get selector() {
    const parent = this.overflowBox.parentElement?.closest("[id]");
    return parent ? `#${parent.id} .ts_heureka_overflowBox` : undefined;
  }

  /*                       */

  set largeMode(state: boolean) {
    this.overflowBox.classList.toggle("heureka_overflowBox--large", state);
  }

  get largeMode() {
    return this.overflowBox.classList.contains("heureka_overflowBox--large");
  }

  set loading(state: boolean) {
    const { scrollLeft } = this.overflowBox.dataset;
    this.overflowBox.classList.toggle("heureka_overflowBox--loading", state);
    if (!state && scrollLeft) {
      this.setScrollLeft(parseInt(scrollLeft));
      delete this.overflowBox.dataset.scrollLeft;
    }
  }

  get loading() {
    return this.overflowBox.classList.contains("heureka_overflowBox--loading");
  }

  set scrollLeft(scrollLeft: number) {
    if (scrollLeft > 0) {
      if (this.loading) {
        this.overflowBox.dataset.scrollLeft = scrollLeft.toString();
      } else {
        this.setScrollLeft(scrollLeft);
      }
    }
  }

  get scrollLeft() {
    return this.overflowBox.scrollLeft;
  }

  private setScrollLeft(scrollLeft: number) {
    this.overflowBox.scrollLeft = scrollLeft;
  }

  initializeCollapsedState(force = false) {
    if (this.loading || force) {
      /*                                                   */
      this.largeMode = !this.initializeVisibilities(true);
      this.initializeVisibilities();
      this.loading = false;
    }
    return this;
  }

  /**
 *
 */
  initializeVisibilities(dryRun = false) {
    const items = this.items;
    if (!this.items.length) {
      return true;
    }

    /*                                                     */
    /*                                                                                                                        */
    const inlineSticky = this.largeMode ? 0 : OverflowBox.width(this.stickyItem);
    const maxRight = OverflowBox.right(this.overflowBox) - inlineSticky;

    if (dryRun && this.stickyItem === null) {
      return true;
    }

    /*                                                               */
    if (dryRun) {
      return this.findOverflowingItems(items, maxRight).length == 0;
    } else {
      return this.hideOverflowingItems(items, maxRight).length == 0;
    }
  }

  private findOverflowingItems(items: NodeListOf<HTMLElement>, maxRight: number) {
    return filterItems(items, (elem) => OverflowBox.right(elem) > maxRight);
  }

  private hideOverflowingItems(items: NodeListOf<HTMLElement>, maxRight: number) {
    return mapItems(items, (elem) =>
      OverflowBoxItem.toggleCollapsedVisibility(elem, OverflowBox.right(elem) > maxRight),
    ).filter((elem) => OverflowBoxItem.isHiddenIfCollapsed(elem));
  }

  get flexible() {
    return this.overflowBox.classList.contains("heureka_overflowBox--flexible");
  }

  removeContent() {
    this.overflowBox.classList.remove("heureka_overflowBox", "ts_heureka_overflowBox");
    mapItems(this.overflowBox.children, (elem) => elem.classList.remove("heureka_overflowBox__item"));
    return this;
  }

  resetScrollListener() {
    return (event: Event) => {
      this.setScrollLeft(0);
      this.items.forEach((val) => {
        OverflowBoxItem.toggleFirstItem(val, false);
      });
      OverflowBoxItem.toggleFirstItem(event.target, true);
    };
  }

  static right(elem: HTMLElement | null) {
    return elem?.getBoundingClientRect().right || 0;
  }

  static width(elem: HTMLElement | null) {
    return elem?.getBoundingClientRect().width || 0;
  }
}

export class OverflowBoxItem {
  static toggleCollapsedVisibility(elem: HTMLElement, state?: boolean) {
    elem.classList.toggle("heureka_overflowBox__option", state);
    return elem;
  }

  public static isVisibleIfCollapsed(elem: HTMLElement) {
    return !elem.closest(".heureka_overflowBox__option");
  }

  public static isHiddenIfCollapsed(elem: HTMLElement) {
    return !elem.closest(".heureka_overflowBox__option");
  }

  static toggleFirstItem(elem: HTMLElement | EventTarget | null, state?: boolean) {
    if (elem instanceof HTMLElement) {
      elem.closest(".heureka_overflowBox__item")?.classList.toggle("heureka_overflowBox__item--first", state);
    }
  }
}

export function prepareOverflowBoxContainers(fragment: DocumentFragment) {
  const overflowBoxContainers = OverflowBoxContainer.allOverflowBoxContainers(fragment);
  OverflowBoxContainer.forEachOverflowBoxContainer((elem, index) => {
    if (index < overflowBoxContainers.length) {
      overflowBoxContainers[index].scrollLeft(elem.getScrollLeft());
    }
  });
}
