import type { FacetOptions } from "@otto-ec/heureka-components/facet";
import type { FacetValueLabelOptions } from "@otto-ec/heureka-components/facet-value-label";
import type { FacetValueOptions } from "@otto-ec/heureka-components/facet-value";
import type { FacetValueGroupOptions } from "@otto-ec/heureka-components/facet-value-group";

export type FilterMode = "filterAccordion" | "filterTitle" | "detailSheet";

export type FacetValueLabelType = "color" | "rating" | "text";
export type FacetValueType = "checkbox" | "chip" | "radio";
export type FacetValueGroupType = "grid" | "list" | "overflowBox";
export type FacetType = "scrollable" | "simple";

export type FacetValueLabelConfiguration = FacetValueLabelOptions & {
  type?: FacetValueLabelType;
};
export type FacetValueConfiguration = FacetValueOptions & {
  type?: FacetValueType;
};
export type FacetValueGroupConfiguration = FacetValueGroupOptions & {
  type?: FacetValueGroupType;
};
export type FacetConfiguration = FacetOptions & {
  type?: FacetType;
};

export type Typed<T> = T extends { type?: infer U } ? U : never;
export type Packed<T> = Typed<T> | T | Partial<Record<FilterMode, T>>;

export type ConfigurableFacetOptions = {
  facetValueLabelConfig?: Packed<FacetValueLabelConfiguration>;
  facetValueConfig?: Packed<FacetValueConfiguration>;
  facetValueGroupConfig?: Packed<FacetValueGroupConfiguration>;
  facetConfig?: Packed<FacetConfiguration>;
};

export function unpack<T>(config: Packed<T>, mode: FilterMode): T {
  return typeof config === "string" ? { type: config } : config[mode] || config;
}
