import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { eventQBus } from "../types/EventQBus";
import type { FilterSectionLoadedEvent } from "../multifiltering/FilterTypes";
import { allElements, appendAll, clear } from "../util/Utils";
import { FilterSorting } from "./FilterSorting";
import { FilterTitleClickAction } from "./FilterTitleClickAction";
import { SubmitOnChangeListener } from "./SubmitOnChange";
import { fireFilterSubmit } from "./FilterFormActions";
import { SortingForm } from "../sorting/SortingForm";
import { FilterTitle } from "./FilterTitle";

const MOBILE_FILTERS_ID = "heureka_filterTitles";
const SELECTOR_DENSE = ".find_filterTitles";
const SELECTOR_EXPANDED = ".find_filterTitles--expanded";
const FILTER_ITEM_SELECTOR = "div[data-filter-id]";

export class FilterTitles {
  private static readonly factory = HeurekaElementFactory.byId(MOBILE_FILTERS_ID, FilterTitles);

  /*               */
  constructor(
    readonly elem: HTMLElement,
    private readonly filters: HTMLElement[] = FilterTitles.selectFilters(elem),
    readonly filterTitles: FilterTitle[] = FilterTitle.factory.all(elem),
  ) {}

  private static selectFilters(elem: HTMLElement) {
    return Array.from(allElements(FILTER_ITEM_SELECTOR, elem));
  }

  /*                  */

  static template(rootElement?: ParentNode | null) {
    return FilterTitles.factory.pick(undefined, rootElement);
  }

  /*               */

  static register() {
    eventQBus.on("heureka.filterSection.loaded", FilterTitles.initAll);
    eventQBus.on("heureka.filterSection.loadAborted", FilterTitles.initAll);
  }

  static initAll(event?: FilterSectionLoadedEvent, rootElement?: ParentNode) {
    FilterTitles.template(rootElement)?.init();
  }

  protected init() {
    FilterTitleClickAction.on(this);
    SubmitOnChangeListener.on(
      this.elem,
      SubmitOnChangeListener.submitOnChange(fireFilterSubmit, SortingForm.factory.selector),
    );
    return this;
  }

  /*                       */

  private get footer() {
    return FilterTitle.footer(this.elem);
  }

  private set footer(footer) {
    if (footer) {
      this.elem.appendChild(footer.elem);
    }
  }

  private get values(): HTMLElement[] {
    return this.filters;
  }

  private set values(filtersToSort: HTMLElement[]) {
    appendAll(filtersToSort, this.elem);
    /*                                                    */
    const { footer } = this;
    this.footer = footer;
  }

  private set valuesDense(filterTitles: HTMLElement[]) {
    const destination = this.elem.querySelector<HTMLElement>(SELECTOR_DENSE);
    if (destination) {
      clear(destination);
      appendAll(filterTitles, destination);
    }
  }

  private set valuesExpanded(filterTitles: HTMLElement[]) {
    const destination = this.elem.querySelector<HTMLElement>(SELECTOR_EXPANDED);
    if (destination) {
      clear(destination);
      appendAll(filterTitles, destination);
    }
  }

  sortValues() {
    const sortedFilters = FilterSorting.sort(this.filterTitles);

    const denseFilterTitles: HTMLElement[] = [];
    const expandedFilterTitles: HTMLElement[] = [];
    sortedFilters.forEach((filterTitle) => {
      const { referencedFilter } = filterTitle;
      if (referencedFilter) {
        if (referencedFilter.isDenseModeFilter) {
          denseFilterTitles.push(filterTitle.elem);
        } else {
          expandedFilterTitles.push(filterTitle.elem);
        }
      }
      /*                                      */
      if (filterTitle.filterId === "localNavigation") {
        denseFilterTitles.unshift(filterTitle.elem);
      }
    });
    const filterSortingFilterTitle = FilterTitle.filterId("find_filter_sorting")?.elem;
    if (filterSortingFilterTitle) {
      denseFilterTitles.unshift(filterSortingFilterTitle);
    }
    this.valuesDense = denseFilterTitles;
    this.valuesExpanded = expandedFilterTitles;
  }
}
