<script lang="ts">
  import type { Size, FacetValueLabel, FacetValueLabelOptions } from "./type";

  interface Props extends FacetValueLabel, FacetValueLabelOptions {
    value: Color;
  }

  type Color =
    | "blau"
    | "schwarz"
    | "grau"
    | "gruen"
    | "weiss"
    | "braun"
    | "beige"
    | "rot"
    | "rosa"
    | "gelb"
    | "lila"
    | "orange"
    | "goldfarben"
    | "silberfarben"
    | "bunt";

  /*                      */
  let { title, titleSize, fitContent = false, size = "100", value, bold }: Props = $props();

  type CircleProps = { cx: string; cy: string; r: string };

  const circleSizes: Record<Size, CircleProps> = {
    "50": { cx: "6", cy: "6", r: "6" },
    "75": { cx: "9", cy: "9", r: "9" },
    "100": { cx: "12", cy: "12", r: "12" },
    "125": { cx: "15", cy: "15", r: "15" },
  };

  const { r, ...cs } = $derived(circleSizes[size]);
</script>

<span class={["hc_color", { "hc_color--grow": !fitContent }]}
  ><svg role="presentation" class={["hc_color__icon", `hc_color__icon--${size}`]}
    ><circle class={["hc_color__circle", `hc_color__circle--${value}`]} {...cs} {r}></circle></svg
  ><span class={["hc_color__title", `hc_color__title--${titleSize || size}`, { "hc_color__title--bold": bold }]}
    >{title}</span
  ></span
>

<style lang="scss">
  .hc_color {
    max-width: 100%;
    display: flex;
    align-items: center;
    gap: var(--oc-semantic-spacing-25);
    font: var(--label-font);

    &--grow {
      width: 100%;
    }

    &__title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font: var(--label-font);

      &--50 {
        --label-font: var(--oc-semantic-font-copy-50);
      }
      &--75 {
        --label-font: var(--oc-semantic-font-copy-75);
      }
      &--100 {
        --label-font: var(--oc-semantic-font-copy-100);
      }
      &--125 {
        --label-font: var(--oc-semantic-font-copy-125);
      }

      &--bold {
        font-weight: 700;
      }
    }

    &__icon {
      height: var(--icon-size);
      width: var(--icon-size);
      min-width: var(--icon-size);
      vertical-align: text-bottom;
      margin-left: calc(-1 * var(--icon-size) / 2);

      &--50 {
        --icon-size: 12px;
      }
      &--75 {
        --icon-size: 18px;
      }
      &--100 {
        --icon-size: 24px;
      }
      &--125 {
        --icon-size: 30px;
      }
    }

    &__circle {
      fill: var(--circle-color);

      &--blau {
        --circle-color: #334982;
      }
      &--schwarz {
        --circle-color: #222;
      }
      &--grau {
        --circle-color: #a6a9a7;
      }
      &--gruen {
        --circle-color: #198504;
      }
      &--weiss {
        --circle-color: #fff;
      }
      &--braun {
        --circle-color: #7e3906;
      }
      &--beige {
        --circle-color: #ccc5a8;
      }
      &--rot {
        --circle-color: #d52b1e;
      }
      &--rosa {
        --circle-color: #ff889e;
      }
      &--gelb {
        --circle-color: #ffd229;
      }
      &--lila {
        --circle-color: #8d0083;
      }
      &--orange {
        --circle-color: #ee9201;
      }
      &--goldfarben {
        --circle-color: url("#goldfarben");
      }
      &--silberfarben {
        --circle-color: url("#silberfarben");
      }
      &--bunt {
        --circle-color: url("#bunt");
      }
    }
  }
</style>
