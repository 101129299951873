<script lang="ts">
  import type { Snippet } from "svelte";
  import type { ClassValue } from "svelte/elements";

  interface Props {
    class?: ClassValue;
    /*                                                   */
    /*                                                                                                     */
    children?: Snippet;
    items: string[];
    filtered?: string[];
    label: string;
    placeholder?: string;
    emptyMessage?: string;
    value?: string;
  }

  let {
    label,
    placeholder = "",
    emptyMessage = "",
    filtered = $bindable([]),
    value = $bindable(""),
    items = [],
  }: Props = $props();

  let searchTerm = $state("");

  $effect(() => {
    filtered = items.filter((item) => item.toLowerCase().includes(searchTerm.toLowerCase()));
  });
</script>

<oc-text-field-v1
  {placeholder}
  type="text"
  value=""
  validation-message={filtered.length === 0 ? emptyMessage : ""}
  oninput={(v: Event) => {
    searchTerm = v.target?.value || "";
    value = searchTerm;
  }}
>
  {label}
</oc-text-field-v1>
