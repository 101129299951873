import { childElements, forEachElement, moveAllChildren, watchEvent } from "../util/Utils";
import type { Sheet } from "@otto-ec/global-pattern";
import { filter, filterFooter, sheetContent, sheetHeader, sheetMenuBar } from "./Selectors";
import { fireFilterSubmit, hasChanges, isForceSubmit } from "../filter/FilterFormActions";
import {
  copyLegacyLabels,
  readLegacyLabel,
  setLegacyLabels,
  updateLabel,
  updateLegacyLabel,
} from "../tracking/FeatureTracking";
import { eventQBus } from "../types/EventQBus";
import { createEmptyFilterHeaderButtons, filterHeaderButtons } from "./FilterSheetHeaderButtons";
import { FilterAccordion } from "../filter/FilterAccordion";
import { OverflowBox } from "../overflowBox/OverflowBox";
import { getActiveSheet } from "../util/NonModuleFunctions";
import { PopularFacetValuesWrapper } from "../filter/PopularFacetValues";
import type { SheetAPI } from "@otto-ec/global-pattern/types/700_Layer_und_Popup/400_pl_sheet/sheetTypes";
import { createSheetTemplate } from "./PaliSheet";
import { Filter } from "../filter/Filter";
import { resetSearchField } from "../filter/SearchableFacet";
import type { TrackingLabels } from "../tracking/TrackingLabels";
import FilterSheet from "./FilterSheet";
import { setActiveConfiguration } from "../components";

function removeFacetChangeNothing(elem: HTMLElement, event: Event, abortWatch: AbortController) {
  const target = event.target as HTMLInputElement;
  if (target.form && (hasChanges(target.form) || isForceSubmit(target.form))) {
    updateLegacyLabel(elem, "san_FacetChangeNothing");
    updateLegacyLabel(elem, "san_FacetSearchTerm");
    updateLegacyLabel(elem, "san_FacetSearchType");
    abortWatch.abort();
  }
}

export function addFormChangeWatcher(form: HTMLFormElement, elem: HTMLElement, resettable = true) {
  const facetChangeNothing = readLegacyLabel(elem, "san_FacetChangeNothing");
  copyLegacyLabels(form, elem);

  if (facetChangeNothing) {
    watchEvent(
      form,
      "input",
      (event, abortWatch) => {
        copyLegacyLabels(form, elem);
        removeFacetChangeNothing(elem, event, abortWatch);
      },
      resettable ? "submit" : undefined,
      () => {
        updateLegacyLabel(elem, "san_FacetChangeNothing", facetChangeNothing);
        copyLegacyLabels(form, elem);
      },
    );
  }
}

export default class FilterDetailSheet {
  constructor(
    private readonly filterId: string,
    private readonly filterTitle?: string,
    private readonly sheet?: Sheet | SheetAPI,
    private readonly accordion?: FilterAccordion,
  ) {}

  public static create(filterId: string, filterTitle?: string) {
    const accordion = FilterAccordion.filterId(filterId);
    return new FilterDetailSheet(filterId, filterTitle, FilterDetailSheet.newSheet(accordion, filterTitle), accordion);
  }

  public static declare(filterId: string, filterTitle?: string) {
    return new FilterDetailSheet(filterId, filterTitle, getActiveSheet(), FilterAccordion.filterId(filterId));
  }

  private static newSheet(accordion?: FilterAccordion, filterTitle?: string) {
    if (accordion) {
      return createSheetTemplate(accordion.filterForm, accordion.filterFooter, true, filterTitle || accordion.filterId);
    }
  }

  setSheet() {
    const form = filter(this.filterId);
    if (form && this.sheet) {
      const footer = filterFooter(form);

      this.sheet.setTitle(this.filterTitle || this.filterId);
      this.sheet.setContent(form);
      this.sheet.setMenuBar(childElements(footer) || []);
    }
    return this;
  }

  initializeSheet() {
    const form = filter(this.filterId);
    if (form && this.sheet) {
      setActiveConfiguration("detailSheet");

      OverflowBox.initAll(undefined, this.sheet.getContent());
      this.sheet.setBackButton(FilterDetailSheet.onClickDetailApply, "");
      const backButton = this.sheet.getBackButton();
      if (backButton) {
        this.prepareBackButton(backButton, form);
      }

      const content = this.sheet.getContent();
      if (content) {
        this.prepareContent(content, form);
        updateLabel(form, "san_FilterFoldoutStatus", "opened");
      }

      const source = this.accordion?.filterHeaderButtons || null;
      moveAllChildren(source, FilterDetailSheet.insertBeforeFilterHeaderButtons(this.sheet.getCloseButton()));
      const popularFacetValuesWrapper = PopularFacetValuesWrapper.factory.pick(undefined, this.sheet.getContent());
      if (popularFacetValuesWrapper) {
        popularFacetValuesWrapper.hidden = true;
      }
      FilterSheet.moveDesktopInlinedFiltersToSheet(content, false);
      this.refreshFacetChangeNothingLabel(this.sheet);
    }
    return this;
  }

  private static insertBeforeFilterHeaderButtons(element?: Element | null) {
    if (element) {
      const container = createEmptyFilterHeaderButtons();
      element.insertAdjacentElement("beforebegin", container);
      return container;
    }
    return null;
  }

  private refreshFacetChangeNothingLabel(sheet: Sheet | SheetAPI) {
    forEachElement(
      ".find_filterSheet__menuBackButton[type=submit]",
      (elem) => updateLegacyLabel(elem, "san_FacetChangeNothing", "adopt_down"),
      sheet.getMenuBar(),
    );
  }

  public ifActive(callback: (sheet: Sheet | SheetAPI) => void) {
    if (this.sheet) {
      callback(this.sheet);
    }
  }

  public open() {
    if (this.sheet) {
      this.sheet.open();
    }
    return this;
  }

  public submitFilter(closeType: string) {
    FilterDetailSheet.submit(this.filterId, closeType);
    return this;
  }

  public resetFilter() {
    const form = filter(this.filterId);
    if (form) {
      form.reset();
    }
    return this;
  }

  public deactivate(trackingLabel: Partial<TrackingLabels>) {
    const source = sheetContent();
    if (source) {
      const popularFacetValuesWrapper = PopularFacetValuesWrapper.factory.pick(undefined, source);
      if (popularFacetValuesWrapper) {
        /*                 */
        const filterId = source.dataset.filterId || "";
        Filter.filterId(filterId)?.facets.map((facet) => resetSearchField(facet));
        popularFacetValuesWrapper.hidden = popularFacetValuesWrapper.allFacetValuesAreHidden();
      }
      FilterSheet.moveInlinedFiltersFrom(source, true, trackingLabel);
      delete source.dataset.filterId;
      delete source.dataset.tsLabels;
    }

    const { accordion } = this;
    if (accordion) {
      const filterHeaderButtonsContainer = filterHeaderButtons(sheetHeader());
      accordion.filterHeaderButtons = filterHeaderButtonsContainer;
      filterHeaderButtonsContainer?.remove();
      accordion.filterForm = filter(this.filterId);
      accordion.filterFooter = sheetMenuBar();
      accordion.filterFoldoutStatus = false;
    }

    return this;
  }

  private prepareBackButton(backButton: HTMLElement, form: HTMLFormElement) {
    backButton.dataset.filterId = this.filterId;

    backButton.classList.add("find_filterSheet__backButton", "js_filterSheetAction");

    setLegacyLabels(backButton, {
      san_FilterFoldoutType: this.filterId,
      san_FilterFoldoutActivity: "close",
      san_FacetChangeNothing: "back",
    });

    addFormChangeWatcher(form, backButton);
  }

  private prepareContent(content: HTMLElement, form: HTMLFormElement) {
    content.scrollTop = 0;
    content.dataset.filterId = this.filterId;

    setLegacyLabels(content, {
      san_FilterFoldoutType: this.filterId,
      san_FilterFoldoutActivity: "close",
      san_FacetChangeNothing: "view_selection",
    });

    addFormChangeWatcher(form, content, false);
  }

  private static onClickDetailApply(event: Event) {
    const target = event.currentTarget as HTMLElement;
    const { filterId = "unknown", filterTitle } = target.dataset;
    FilterDetailSheet.submit(filterId, "adopt");
    eventQBus.emit("ftfind.filterSheet.closeDetail", {
      filterId,
      filterTitle,
    });
  }

  private static submit(filterId: string, filterMethod: string) {
    const elem = filter(filterId);
    if (elem instanceof HTMLFormElement) {
      elem.dataset.tsFeatureFilterMethod = filterMethod;
      fireFilterSubmit(elem);
    }
  }

  private static overwriteSanFacetLocationLabel(
    sheetContent: HTMLElement,
    sanFacetLocation: TrackingLabels["san_FacetLocation"],
  ) {
    forEachElement(
      ".find_facet__content input",
      (facetValue) => {
        updateLabel(facetValue, "san_FacetLocation", sanFacetLocation);
      },
      sheetContent,
    );
  }
}
