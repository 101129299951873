import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { toArray } from "../util/Utils";
import { Filter } from "./Filter";
import { FacetGroup } from "./FacetGroup";
import { any } from "../util/Reductions";
import { eventQBus } from "../types/EventQBus";
import { FacetGroupSelectAction } from "./FacetGroupSelectAction";

const FACET_GROUP_SELECT_CLASS = "heureka_facetGroupSelect";

export class FacetGroupSelect {
  static readonly factory = HeurekaElementFactory.byElement("select", FACET_GROUP_SELECT_CLASS, FacetGroupSelect);

  /*               */
  constructor(readonly select: HTMLSelectElement) {}

  /*                  */

  /*               */

  static register() {
    eventQBus.on("heureka.filterSection.loaded", FacetGroupSelect.initAll);
  }

  private static initAll() {
    FacetGroupSelect.factory.forEach((select) => select.init());
  }

  static facetGroupSelectId(id: string, root?: NonElementParentNode) {
    return FacetGroupSelect.factory.byId(id, root);
  }

  init() {
    this.ensureValidState();
    FacetGroupSelectAction.on(this);
  }

  static prepareAll(fragment: DocumentFragment) {
    FacetGroupSelect.factory.forEach((facetGroupSelect) => facetGroupSelect.prepare(), fragment);
  }

  prepare() {
    const oldFacetGroupSelect = FacetGroupSelect.facetGroupSelectId(this.id);
    const oldSelectedOption = oldFacetGroupSelect?.selectedOption?.value;
    if (oldSelectedOption) {
      this.defaultFacet = oldFacetGroupSelect?.defaultFacet;
      this.select.selectedIndex = this.optionIndex((option) => option.value === oldSelectedOption);
      this.activateFacetGroup(oldSelectedOption);
    }
  }

  private ensureValidState() {
    const { selectedOption } = this;
    const isAnyFacetGroupActive = this.facetGroups.map((facetGroup) => facetGroup.active).reduce(any);
    if ((selectedOption && selectedOption.hidden) || !selectedOption || !isAnyFacetGroupActive) {
      /*                                                                                   */
      this.activateFacetGroup();
    }
    this.enable();
  }

  /*                       */

  get id(): string {
    return this.select.id;
  }

  get filter() {
    return Filter.factory.declare(this.select.form);
  }

  get facetGroups(): FacetGroup[] {
    return this.filter?.facetGroups || [];
  }

  private get options() {
    return toArray<HTMLOptionElement>(this.select.options);
  }

  get selectedOption() {
    return this.select.options.item(this.select.selectedIndex);
  }

  get defaultFacet(): string {
    return this.select.dataset.defaultFacet || "none";
  }

  set defaultFacet(defaultFacet) {
    this.select.dataset.defaultFacet = defaultFacet;
  }

  private enable() {
    this.select.disabled = this.options.filter((option) => !option.hidden).length <= 1;
  }

  private optionIndex(predicate: (option: HTMLOptionElement) => boolean, randomize?: boolean): number {
    if (randomize) {
      return FacetGroupSelect.randomOptionIndex(this.options, predicate);
    }
    return this.options.findIndex(predicate);
  }

  static randomOptionIndex(options: HTMLOptionElement[], predicate: (option: HTMLOptionElement) => boolean) {
    const visibleHiddenOptions = options.filter(predicate);
    const randomVisibleIndex = Math.floor(Math.random() * visibleHiddenOptions.length);
    const randomValue = visibleHiddenOptions[randomVisibleIndex].value;
    return options.findIndex((option) => option.value === randomValue);
  }

  /**
 *
 *
 *
 */
  activateFacetGroup(name?: string) {
    return this.facetGroups.map(this.toggleFacetGroupVisibility(name)).reduce(any);
  }

  private toggleFacetGroupVisibility(name?: string): (factGroup: FacetGroup, index: number) => boolean {
    if (!name) {
      /*                                     */
      const fallbackIndex = this.optionIndex((option) => !option.hidden, false);
      this.select.selectedIndex = fallbackIndex;
      return (facetGroup, index) => facetGroup.toggleVisibility(index === fallbackIndex);
    }
    return (facetGroup) => facetGroup.toggleVisibility(facetGroup.hasName(name));
  }
}
