<script lang="ts">
  import Switch from "../input/SwitchInput.svelte";
  import type { FacetValue, FacetValueOptions } from "./type.ts";
  import { MultiSelect } from "../facet-value-input";
  import { Text } from "../facet-value-label";

  interface Props extends FacetValue, FacetValueOptions {
    type?: "radio" | "checkbox";
  }

  let { input = checkbox, children = text, ...rest }: Props = $props();
</script>

{#snippet checkbox()}
  <MultiSelect {...rest} />
{/snippet}

{#snippet text()}
  <Text bold {...rest} />
{/snippet}

<Switch class="hc_facetValue" labelPlacement="left" {input} {children} />
