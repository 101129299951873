import { eventQBus } from "../types/EventQBus";
import { filterItems, forEachElement, mapElements, mapItems } from "../util/Utils";
import { submitEventTracking } from "../tracking/TrackingUtils";
import type { TrackingLabels } from "../tracking/TrackingLabels";

export type ToggleType = "more" | "chevron";

function left(elem: HTMLElement | null) {
  return elem?.getBoundingClientRect().left || 0;
}

function right(elem: HTMLElement | null) {
  return elem?.getBoundingClientRect().right || 0;
}

function width(elem: HTMLElement | null) {
  return elem?.getBoundingClientRect().width || 0;
}

export class Expander {
  private constructor(private readonly expander: HTMLElement) {}

  private static create(elem: HTMLElement) {
    return new Expander(elem);
  }

  public static declareExpander(elem: HTMLElement | EventTarget | null) {
    if (elem instanceof HTMLElement) {
      return elem.classList.contains("ts_heureka_expander") ? Expander.create(elem) : undefined;
    }
  }

  public static forEachExpander(callback: (expander: Expander, index: number) => void, rootElement?: ParentNode) {
    forEachElement(".ts_heureka_expander", (elem, index) => callback(Expander.create(elem), index), rootElement);
  }

  public static allExpanders(root?: ParentNode) {
    return mapElements(".ts_heureka_expander", Expander.create, root);
  }

  public initializeExpanderListeners() {
    const abort = new AbortController();
    const signal = abort.signal;

    this.expander.addEventListener("click", onClickExpanderToggle, { passive: true });
    this.expander.addEventListener("click", onWatchExpansionChange.bind(this.expander, abort), {
      passive: true,
      signal,
    });

    return this;
  }

  public loading(state: boolean) {
    ExpanderContent.declareExpanderContent(this.expander)?.loading(state);
    return this;
  }

  public toggle(force?: boolean) {
    this.expander.classList.toggle("heureka_expander--expanded", force);
    return this;
  }

  public expand() {
    return this.toggle(true);
  }

  public isExpanded() {
    return this.expander.classList.contains("heureka_expander--expanded");
  }

  public collapse() {
    return this.toggle(false);
  }

  public isCollapsed() {
    return !this.isExpanded();
  }

  public scrollLeft(scrollLeft: number) {
    const expanderContent = ExpanderContent.declareExpanderContent(this.expander);
    if (expanderContent) {
      expanderContent.scrollLeft(scrollLeft);
    } else {
      this.expander.dataset.scrollLeft = scrollLeft.toString();
    }
    return this;
  }

  public getScrollLeft() {
    return ExpanderContent.declareExpanderContent(this.expander)?.getScrollLeft() || 0;
  }

  public getClickTracking() {
    const tsClick = this.expander.dataset["tsClick"];
    let tracking: Partial<TrackingLabels> | undefined;
    if (tsClick) {
      tracking = JSON.parse(tsClick) as Partial<TrackingLabels>;
    }
    return tracking;
  }

  public initializeCollapsedState(force = false) {
    const content = ExpanderContent.declareExpanderContent(this.expander);
    if (content && (content.isLoading() || force)) {
      const allFit = content.initializeVisibilities();
      if (allFit) {
        /*                                */
        forEachElement(".heureka_expander__toggle", ExpanderItem.hideIfCollapsed, this.expander);
      }
      content.loading(false);
    }
    return this;
  }

  setContent(expanderContent: HTMLElement) {
    expanderContent.classList.add(
      "heureka_expander__content",
      "heureka_expander__content--loading",
      "heureka_expander__content--sliding",
    );
    mapItems(expanderContent.children, (elem) => elem.classList.add("heureka_expander__item"));
    const { scrollLeft } = this.expander.dataset;
    if (scrollLeft) {
      this.scrollLeft(parseInt(scrollLeft));
      delete this.expander.dataset.scrollLeft;
    }
    return this;
  }

  selectAndSetContent(selector: string) {
    forEachElement(
      selector,
      (expanderContent) => {
        this.setContent(expanderContent);
      },
      this.expander,
    );
    return this;
  }

  removeContent() {
    const expanderContent = ExpanderContent.declareExpanderContent(this.expander);
    if (expanderContent) {
      expanderContent.removeContent();
    }
  }
}

export class ExpanderContent {
  private isTogglePartOfContent: boolean;

  private constructor(
    private readonly content: HTMLElement,
    private readonly items: NodeListOf<HTMLElement>,
    private readonly toggle: HTMLElement | null,
  ) {
    this.content = content;
    this.items = items;
    this.toggle = toggle;
    this.isTogglePartOfContent = this.toggle?.closest(".heureka_expander__content") !== null;
  }

  static declareExpanderContent(elem: HTMLElement) {
    const content = elem.querySelector<HTMLElement>(".heureka_expander__content");
    return content
      ? new ExpanderContent(
          content,
          elem.querySelectorAll<HTMLElement>(".heureka_expander__content > .heureka_expander__item"),
          elem.querySelector<HTMLElement>(".heureka_expander__toggle"),
        )
      : undefined;
  }

  public loading(state: boolean) {
    const { scrollLeft } = this.content.dataset;
    this.content.classList.toggle("heureka_expander__content--loading", state);
    if (!state && scrollLeft) {
      this.setScrollLeft(parseInt(scrollLeft));
      delete this.content.dataset.scrollLeft;
    }
    return this;
  }

  public isLoading() {
    return this.content.classList.contains("heureka_expander__content--loading");
  }

  public scrollLeft(scrollLeft: number) {
    if (scrollLeft > 0) {
      if (this.isLoading()) {
        this.content.dataset.scrollLeft = scrollLeft.toString();
      } else {
        this.setScrollLeft(scrollLeft);
      }
    }
    return this;
  }

  private setScrollLeft(scrollLeft: number) {
    this.content.scrollLeft = scrollLeft;
  }

  public getScrollLeft() {
    return this.content.scrollLeft;
  }

  public getToggle() {
    return this.content.querySelector<HTMLElement>(".heureka_expander__toggle");
  }

  /**
 *
 */
  public initializeVisibilities() {
    const items = this.items;
    if (!this.items.length) {
      return true;
    }

    /*                                */
    const innerMargin = left(items[0]) - left(this.content);
    const boxRight = right(this.content) - innerMargin;
    /*                               */
    if (right(items[items.length - 1]) > 0 && right(items[items.length - 1]) <= boxRight) {
      /*                                */
      return true;
    }

    /*           */
    const toggleWidth = this.isTogglePartOfContent ? width(this.toggle) : 0;
    const maxRight = boxRight - toggleWidth - innerMargin;
    filterItems(items, (elem) => right(elem) <= maxRight).map(ExpanderItem.showIfCollapsed);
    const hiddenItems = filterItems(items, (elem) => right(elem) > maxRight).map(ExpanderItem.hideIfCollapsed);
    return hiddenItems.length == 0;
  }

  removeContent() {
    this.content.classList.remove(
      "heureka_expander__content",
      "heureka_expander__content--loading",
      "heureka_expander__content--sliding",
    );
    mapItems(this.content.children, (elem) => elem.classList.remove("heureka_expander__item"));
    return this;
  }
}

export class ExpanderToggle {
  private constructor(private readonly toggle: HTMLElement) {}

  private static create(elem: HTMLElement) {
    return new ExpanderToggle(elem);
  }

  public static declareExpanderToggle(elem: HTMLElement | EventTarget | null) {
    if (elem instanceof HTMLElement) {
      return elem.classList.contains("heureka_expander__toggle") ? ExpanderToggle.create(elem) : undefined;
    }
  }

  public getToggleType(): ToggleType {
    return this.toggle.classList.contains("heureka_expander__toggle--more") ? "more" : "chevron";
  }
}

export class ExpanderItem {
  static hideIfCollapsed(elem: HTMLElement) {
    elem.classList.add("heureka_expander__option");
    return elem;
  }

  static showIfCollapsed(elem: HTMLElement) {
    elem.classList.remove("heureka_expander__option");
    return elem;
  }

  public static isVisibleIfCollapsed(elem: HTMLElement) {
    return !elem.closest(".heureka_expander__option");
  }
}

function onClickExpanderToggle(event: Event) {
  const expander = Expander.declareExpander(event.currentTarget);
  const target = ExpanderToggle.declareExpanderToggle(event.target);
  if (expander && target) {
    const tracking = expander.getClickTracking();
    if (tracking) {
      submitEventTracking(tracking);
    }

    expander.toggle();
  }
}

function onWatchExpansionChange(watch: AbortController, event: Event) {
  const expander = Expander.declareExpander(event.currentTarget);
  const target = ExpanderToggle.declareExpanderToggle(event.target);
  if (expander && target) {
    /*                                                                                                 */
    /*                                                                                   */
    if (expander.isCollapsed()) {
      expander.initializeCollapsedState(true);
    }
    /*                                                                                                           */
    watch.abort();
  }
}

function initExpander(expander: Expander) {
  expander.initializeExpanderListeners().initializeCollapsedState();
}

function onFilterSectionLoaded() {
  Expander.forEachExpander(initExpander);
}

export function initializeExpandersIn(rootElement: HTMLElement) {
  Expander.forEachExpander(initExpander, rootElement);
}

export function prepareExpander(fragment: ParentNode) {
  const expanders = Expander.allExpanders(fragment);
  Expander.forEachExpander((elem, index) => {
    if (index < expanders.length) {
      if (elem.isExpanded()) {
        expanders[index].expand().loading(false);
      } else {
        expanders[index].scrollLeft(elem.getScrollLeft());
      }
    }
  });
}

export default function registerExpanderHandlers() {
  eventQBus.on("heureka.filterSection.loaded", onFilterSectionLoaded);
}
