import { SortingInput } from "./SortingInput";
import { SortingForm } from "./SortingForm";
import { SortingSubmitAction } from "./SortingSubmitAction";
import { SmartSorting } from "./SmartSorting";
import { isActive } from "../toggle/Toggle";
import { SmartSortingWhatIfTracking } from "./SmartSortingWhatIfTracking";

export function registerSorting() {
  SmartSorting.register();
  SortingInput.register();
  SortingForm.register();
  SortingSubmitAction.register();
  if (isActive("HEUREKA_1282_SMART_SORTING_NEXT_GENERATION_E3387")) {
    SmartSortingWhatIfTracking.register();
  }
}
