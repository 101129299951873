import { eventQBus } from "../types/EventQBus";
import { Assets, ConfigurableFacet } from "../components";
import { mount } from "svelte";
import type { ConfigurableFacetOptions } from "../components/type";
import { mapElements } from "../util/Utils";

function initializeConfigurableFacet() {
  mapElements(".ts_heureka_svelteFacet", (elem) => {
    const parentElement = elem.parentElement as HTMLElement;
    const { filterId, facetVisibility, serialized } = elem.dataset;

    const config: Record<string, ConfigurableFacetOptions> = {
      farbe: {
        facetValueLabelConfig: {
          filterAccordion: {
            type: "color",
            titleSize: "100",
          },
          filterTitle: {
            type: "color",
            titleSize: "75",
          },
        },
        facetValueConfig: "chip",
        facetConfig: "simple",
        facetValueGroupConfig: {
          filterAccordion: {
            type: "grid",
          },
          filterTitle: {
            type: "overflowBox",
          },
        },
      },
      bewertung: {
        facetValueLabelConfig: "rating",
        facetValueConfig: "radio",
        facetConfig: "simple",
        facetValueGroupConfig: "list",
      },
      material: {
        facetValueLabelConfig: "text",
        facetValueConfig: "checkbox",
        facetConfig: "scrollable",
        facetValueGroupConfig: "list",
      },
    };

    mount(ConfigurableFacet, {
      target: parentElement,
      props: {
        data: JSON.parse(serialized),
        parentId: `find_filter_${filterId}`,
        visibility: facetVisibility,
        ...config[filterId],
      },
    });
    elem.remove();
    return parentElement;
  });
}

function initializeAssets() {
  mount(Assets, {
    target: document.body,
  });
}

export default function registerSvelteComponentInitialization() {
  eventQBus.on("heureka.filterSection.loaded", initializeConfigurableFacet);
  eventQBus.once("heureka.filterSection.loaded", initializeAssets);
}
