import type { FacetValue } from "../facet-value";

type ArrayFunction<T, U> = (value: T, index: number, array: T[]) => U;
type ArrayUnaryFunction<T> = ArrayFunction<T, T>;
type ArrayPredicate<T> = (value: T, index: number, array: T[]) => boolean;

export const not =
  <T>(predicate: ArrayPredicate<T>): ArrayPredicate<T> =>
  (...args) =>
    !predicate(...args);

/*                                     */

export const isSelected: ArrayPredicate<FacetValue> = (value: FacetValue) => value.checked;
export const isNotSelected: ArrayPredicate<FacetValue> = not(isSelected);

export const addNameAndFeatureTracking =
  (parentId: string, name: string, offset: number): ArrayUnaryFunction<FacetValue> =>
  ({ featureTracking, ...rest }: FacetValue, index: number) => ({
    name,
    ...rest,
    ...(featureTracking && { featureTracking: { parentId, position: `${index + offset}`, ...featureTracking } }),
  });
