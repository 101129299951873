<script lang="ts">
  import type { ClassValue } from "svelte/elements";
  import type { FacetValueInput, FacetValueInputOptions } from "./type.ts";
  import inputDefault from "../facet-value/InputDefaultAction.ts";
  import { removeStatusIf, featureTrackingAttributes } from "../tracking";

  interface Props extends FacetValueInput, FacetValueInputOptions {
    id?: string;
    class?: ClassValue;
    selectedValues?: string[];
  }

  let {
    name,
    value,
    checked,
    "aria-label": ariaLabel,
    featureTracking,
    id = `${name}_${featureTracking?.id}`,
    selectedValues = $bindable(),
    ...rest
  }: Props = $props();

  /*                     */
  const tracking = $derived(removeStatusIf(!checked, featureTracking));

  /*                            */
  ariaLabel ||= rest.forceAriaLabel ? rest.title : undefined;

  function onchange({ currentTarget }: Event & { currentTarget: HTMLInputElement }) {
    selectedValues = currentTarget.checked
      ? [...(selectedValues || []), currentTarget.value]
      : selectedValues?.filter((v) => v !== currentTarget.value);
  }
</script>

<input
  {id}
  class={["js_tValue", rest.class]}
  {name}
  type="checkbox"
  {value}
  {checked}
  aria-label={ariaLabel}
  {...featureTrackingAttributes(tracking)}
  use:inputDefault
  {onchange}
/>
