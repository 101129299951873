import { eventQBus } from "../types/EventQBus";
import type { DresonRuleResolvedEvent } from "./FilterTypes";
import { LoadFilter } from "./LoadFilter";
import { TsBct } from "./TsBct";
import { addFeaturesToPageImpressionTracking } from "../tracking/FeatureTracking";
import { Filter } from "../filter/Filter";
import { addSanErrorToPageImpression, FILTER_FEATURES } from "../filter/FilterTracking";
import { Facet } from "../filter/Facet";
import { FilterSection } from "../filter/FilterSection";

export function registerFilterReloading() {
  eventQBus.on("ftfind.dresonRule.resolved", (data: DresonRuleResolvedEvent) => {
    if (data && data.rule !== FilterSection.template().currentDresonRule) {
      if (data.count === 0) {
        Filter.handleError();
        addSanErrorToPageImpression("filterlist_empty");
        addFeaturesToPageImpressionTracking(FILTER_FEATURES);
      } else {
        eventQBus.emit("heureka.filterSection.load", { ...data, ssid: TsBct.sfid() });
      }
    } else {
      Facet.facetName("kategorien")?.allFacetValues.forEach((facetValue) => {
        /*                                                    */
        facetValue.toggleChecked(facetValue.checked);
      });
    }
  });

  eventQBus.on("ftfind.dresonRule.resolveAborted", Filter.handleError);

  eventQBus.on("heureka.filterSection.load", LoadFilter.onLoadFilters);

  eventQBus.on("heureka.filterSection.loadAborted", () => eventQBus.emit("heureka.filterSection.loaded"));

  eventQBus.on("heureka.filterSection.initialLoaded", LoadFilter.onInitialLoadedFilters);
}
