import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { updatePosition } from "../tracking/FeatureTracking";
import { FacetValue } from "./FacetValue";
import { Facet } from "./Facet";
import { AbstractFacetValue } from "./AbstractFacetValue";

export const POPULAR_FACET_VALUE_CLASS = "ts_heureka_popularFacetValues_value";
const POPULAR_FACET_VALUE_CHECKED_CLASS = "heureka_popularFacetValues_value--selected";

export class PopularFacetValue extends AbstractFacetValue {
  static readonly factory = HeurekaElementFactory.byClass(POPULAR_FACET_VALUE_CLASS, PopularFacetValue);
  readonly label: HTMLLabelElement | null;
  readonly input: HTMLInputElement | null;
  readonly span: HTMLSpanElement | null;

  /*               */
  constructor(readonly popularFacetValue: HTMLElement) {
    super();
    this.label = popularFacetValue.querySelector("label");
    this.input = popularFacetValue.querySelector<HTMLInputElement>("input");
    this.span = popularFacetValue.querySelector("span");
  }

  /*                       */

  get associatedFacetValue(): FacetValue | undefined {
    const { value, facet, featureRef } = this;
    if (featureRef) {
      return FacetValue.fromId(featureRef, facet?.facet);
    } else if (value) {
      return facet?.getFacetValue(value);
    }
  }

  get associatedFacetValueInput(): HTMLInputElement | null {
    return this.associatedFacetValue?.input || null;
  }

  get facet() {
    return Facet.factory.closest(this.popularFacetValue);
  }

  get name(): string | undefined {
    return this.input?.name;
  }

  get value(): string | undefined {
    return this.input?.value;
  }

  get title(): string {
    return this.label?.title || "";
  }

  public get hidden(): boolean {
    return this.popularFacetValue.hidden;
  }

  public set hidden(hidden) {
    this.popularFacetValue.hidden = hidden;
  }

  public get disabled(): boolean {
    return this.input?.disabled || false;
  }

  public set disabled(disable) {
    if (this.input) {
      this.input.disabled = disable;
      /*                                                                        */
      /*                                                     */
      this.input.type = disable || !this.isRadioButton() ? "checkbox" : "radio";
    }
  }

  get visibleAndNotChecked(): boolean {
    /*                                       */
    return !this.hidden && !this.checked;
  }

  set position(position: number) {
    const associatedFacetValue = this.associatedFacetValueInput;
    if (associatedFacetValue) {
      updatePosition(associatedFacetValue, position);
    }
  }

  private get checked() {
    return this.popularFacetValue.classList.contains(POPULAR_FACET_VALUE_CHECKED_CLASS);
  }

  private set checked(state: boolean) {
    const { input } = this;
    if (input) {
      this.popularFacetValue.classList.toggle(POPULAR_FACET_VALUE_CHECKED_CLASS, state);
      input.checked = state;
    }
  }

  get featureRef(): string | undefined {
    return this.input?.dataset.tsFeatureRef;
  }

  set spanInnerHTML(innerHTML: string) {
    if (this.span) {
      this.span.innerHTML = innerHTML;
    }
  }

  isRadioButton(): boolean {
    return this.associatedFacetValueInput?.classList.contains("pl_radio__button") || false;
  }

  initialize() {
    return this.copyCount().copyTitle().copyTextContent().copyCheckedState();
  }

  isMatching() {
    return this.associatedFacetValue !== undefined;
  }

  updateVisibility() {
    if (this.associatedFacetValue) {
      /*                                    */
      this.hidden = false;
    } else {
      /*                                                           */
      if (this.input?.dataset.tsFeatureRef) {
        /*                                      */
        this.hidden = true;
      }
    }
    return this;
  }

  private copyTextContent() {
    const associatedFacetValueTitle = this.associatedFacetValue?.span;
    const popularFacetValueTitle = this.span;
    if (associatedFacetValueTitle && popularFacetValueTitle) {
      popularFacetValueTitle.textContent = associatedFacetValueTitle.textContent;
    }
    return this;
  }

  private copyTitle() {
    const associatedFacetValueLabel = this.associatedFacetValue?.label;
    const popularFacetValueLabel = this.label;
    if (associatedFacetValueLabel && popularFacetValueLabel) {
      popularFacetValueLabel.title = associatedFacetValueLabel.title;
    }
    return this;
  }

  private copyCount() {
    const hiddenCount = this.associatedFacetValueInput?.closest<HTMLElement>("[data-hidden-count]");
    const label = this.input?.nextElementSibling as HTMLElement;
    if (label?.classList.contains("js_hasPaliTooltip") && hiddenCount) {
      const displayName = label.dataset.tooltip;
      label.dataset.tooltip = `${displayName} <span>${hiddenCount.dataset.hiddenCount}</span>`;
      return this;
    }
    const sourceCount = this.associatedFacetValueInput?.closest<HTMLElement>("[data-count]");
    const targetCount = this.input?.closest<HTMLElement>("[data-count]");
    if (sourceCount && targetCount) {
      targetCount.dataset.count = sourceCount.dataset.count;
    }
    return this;
  }

  private copyCheckedState() {
    const popularFacetValueInput = this.input;
    const associatedInput = this.associatedFacetValueInput;
    if (popularFacetValueInput && associatedInput) {
      this.checked = associatedInput.checked;
      popularFacetValueInput.defaultChecked = associatedInput.defaultChecked;
    }
    return this;
  }
}
