import { prepareExpander } from "../expander/Expander";
import { OverflowBox, prepareOverflowBoxContainers } from "../overflowBox/OverflowBox";
import { FilterAccordion } from "./FilterAccordion";
import { SmartSorting } from "../sorting/SmartSorting";
import { FilterTitle } from "./FilterTitle";
import { Facet } from "./Facet";
import { FacetGroupSelect } from "./FacetGroupSelect";
import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { eventQBus } from "../types/EventQBus";
import type { DresonRuleResolvedEvent, FilterSectionLoadedEvent } from "../multifiltering/FilterTypes";
import { element } from "../util/Utils";
import { FilterRelevancy } from "./FilterRelevancy";
import { isActive } from "../toggle/Toggle";
import { FilterlistExpander } from "../filterlistExpander/FilterlistExpander";
import { Filter } from "./Filter";
import { SmartSortingWhatIfTracking } from "../sorting/SmartSortingWhatIfTracking";

const FILTER_SECTION_ID = "find_filterSection";
const ERROR_MESSAGE_SELECTOR = ".heureka_filterSection--errorMessage";

export class FilterSection {
  static readonly factory = HeurekaElementFactory.byId(FILTER_SECTION_ID, FilterSection);

  /*               */
  constructor(readonly filterSection: HTMLElement) {}

  /*                  */
  static template(rootElement?: ParentNode | null) {
    return FilterSection.factory.pick(undefined, rootElement);
  }

  /*               */
  static register() {
    /*      */
    eventQBus.on("ftfind.dresonRule.resolve", FilterSection.lockAll);
    eventQBus.on("ftfind.dresonRule.resolved", FilterSection.unlockAllImmediately);
    eventQBus.on("ftfind.dresonRule.resolveAborted", FilterSection.unlockAll);

    /*        */
    eventQBus.on("heureka.filterSection.loaded", FilterSection.unlockAll);
    eventQBus.on("heureka.filterSection.loadAborted", FilterSection.unlockAll);

    /*     */
    eventQBus.on("heureka.filterSection.loadAborted", FilterSection.showErrorMessage);
  }

  static initAll(event: FilterSectionLoadedEvent, rootElement?: ParentNode) {
    FilterSection.factory.forEach((filterSection) => filterSection.init(), rootElement);
  }

  static prepare(fragment: DocumentFragment, initialLoad = false) {
    prepareExpander(fragment);
    OverflowBox.prepareAll(fragment);
    prepareOverflowBoxContainers(fragment);
    FilterRelevancy.prepareAll(fragment, initialLoad);
    Facet.prepareAll(fragment);
    FilterAccordion.prepareAll(fragment);
    FilterTitle.prepareAll(fragment);
    SmartSorting.prepareAll(fragment);
    if (isActive("HEUREKA_1282_SMART_SORTING_NEXT_GENERATION_E3387")) {
      SmartSortingWhatIfTracking.prepareAll(fragment);
    }
    FacetGroupSelect.prepareAll(fragment);
    Filter.prepareAll(fragment, initialLoad);
    FilterlistExpander.prepareAll(fragment);
  }

  static showErrorMessage() {
    FilterSection.factory.forEach((filterSection) => filterSection.showError());
  }

  static lockAll() {
    FilterSection.factory.forEach((filterSection) => filterSection.lock());
  }

  static unlockAll() {
    FilterSection.factory.forEach((filterSection) => filterSection.unlock());
  }

  static unlockAllImmediately(event: DresonRuleResolvedEvent) {
    if (event.count == 0 || event.rule === FilterSection.template().currentDresonRule) {
      /*                 */
      FilterSection.unlockAll();
    }
  }

  public get currentDresonRule() {
    return this.filterSection.getAttribute("data-rule");
  }

  protected init() {
    return this;
  }

  public lock() {
    this.filterSection.classList.add("filterWrapper--loading");
    return this;
  }

  public unlock() {
    this.filterSection.classList.remove("filterWrapper--loading");
    return this;
  }

  public get errorMessage() {
    return element(ERROR_MESSAGE_SELECTOR, this.filterSection);
  }

  public showError() {
    const { errorMessage } = this;
    if (errorMessage) {
      errorMessage.hidden = false;
    }
  }
}
