import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { updateWhatIfTracking } from "../tracking/WhatIfTracking";
import { eventQBus } from "../types/EventQBus";
import type { FilterSectionLoadedEvent } from "../multifiltering/FilterTypes";

const SHADOW_FACET_VISIBILITIES_ID = "heureka_shadowFacetVisibilities";

export class ShadowFacetTracking {
  static readonly factory = HeurekaElementFactory.byId(SHADOW_FACET_VISIBILITIES_ID, ShadowFacetTracking);

  constructor(readonly elem: HTMLElement) {}

  /*                  */

  /*               */

  public static register() {
    eventQBus.on("heureka.filterSection.loaded", ShadowFacetTracking.initAll);
  }

  static initAll(event: FilterSectionLoadedEvent, rootElement?: ParentNode) {
    ShadowFacetTracking.factory.forEach((item) => item.init(), rootElement);
  }

  private init() {
    this.updateWhatIfTracking();
  }

  /*                       */

  get shadowFacetVisibilities(): string | undefined {
    return this.elem.dataset.shadowFacetVisibilities;
  }

  /*       */

  private updateWhatIfTracking() {
    const { shadowFacetVisibilities } = this;
    if (shadowFacetVisibilities && shadowFacetVisibilities.length) {
      updateWhatIfTracking(shadowFacetVisibilities);
    }
  }
}
