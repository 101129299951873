<script lang="ts">
  import type { Snippet } from "svelte";

  interface Props {
    input?: Snippet;
    children?: Snippet;
  }

  let { input = defaultInput, children }: Props = $props();
</script>

{#snippet defaultInput()}
  <input type="checkbox" />
{/snippet}

<label class={["hc_chip"]}>
  {@render input()}
  <span class="hc_chip__handle">
    {@render children?.()}
  </span>
</label>

<style lang="scss">
  .hc_chip {
    --input-color: var(--oc-base-color-gray-25);
    --input-height: var(--oc-base-dimension-relative-32);
    --input-width: 100%;
    --input-border-color: var(--oc-base-color-gray-200);
    --input-border-radius: calc(var(--input-height) / 2);
    --thumb-color: var(--oc-base-color-white);
    --thumb-size: var(--oc-base-dimension-relative-24);
    --checkmark-size: var(--oc-base-dimension-relative-16);
    --checkmark-color-selected: var(--oc-semantic-color-text-default);
    --grid-columns: [input-start] var(--input-border-radius) [thumb-start] var(--label-width, max-content) [thumb-end]
      var(--input-border-radius) [input-end];

    align-items: center;
    cursor: pointer;
    grid-template-columns: var(--grid-columns);
    justify-items: center;

    &:not([hidden]) {
      display: grid;
    }

    &__handle {
      grid-column: thumb-start / thumb-end;
      grid-row: 1;
      display: flex;

      align-items: center;
      justify-content: center;
      width: 100%;
      border-radius: var(--input-border-radius);

      @at-root :global(input):checked ~ & {
        font-weight: bold;
      }
    }

    :global(input) {
      appearance: none;
      cursor: pointer;
      margin: 0;
      height: var(--input-height);
      width: var(--input-width);
      border-radius: var(--input-border-radius);
      grid-column: input-start / input-end;
      grid-row: 1;

      &:not(:checked) {
        background-color: var(--input-color);
      }

      &:checked {
        background-color: var(--oc-base-color-interactive-blue-50);
      }

      &:focus-visible {
        outline: var(--oc-semantic-focus-outline-color, #008cf8) var(--oc-semantic-focus-outline-style, solid)
          var(--oc-semantic-focus-outline-width, 2px);
        outline-offset: var(--oc-semantic-focus-outline-offset, 2px);
      }
    }

    @media (hover: hover) {
      @at-root &:hover :global(input) {
        &:not(:checked) {
          background-color: var(--oc-base-color-black-lighten-71);
        }

        &:checked {
          background-color: var(--oc-base-color-interactive-blue-lighten-35);
        }
      }
      @at-root &:hover &__handle {
        outline-width: var(--oc-semantic-focus-outline-offset, 2px);
      }
    }
  }
</style>
