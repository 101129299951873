<script lang="ts">
  import type { Interval } from "../type";
  import { type FeatureTrackingReference, featureTrackingReferenceAttributes } from "../../tracking";
  import observe from "../ObserveAction.ts";
  import getScale, { type Scale } from "./Scale.ts";
  import { DeJitteringScale, RoundingScale } from "./Denoise.ts";

  interface Props {
    range: Interval;
    availableRange: Required<Interval>;
    logarithmic?: boolean;
    scale?: Scale;
    step?: "any" | number;
    natural?: boolean;
    featureTrackingReference?: FeatureTrackingReference;
  }

  let {
    range = $bindable(),
    availableRange,
    logarithmic = false,
    scale = getScale(logarithmic),
    step = logarithmic ? "any" : 1,
    natural = false,
    featureTrackingReference,
  }: Props = $props();

  const _scale = natural
    ? DeJitteringScale.deJitter(scale, availableRange.from, availableRange.to)
    : new RoundingScale(scale);

  const availableValueMin = _scale.toValue(availableRange.from);
  const availableValueMax = _scale.toValue(availableRange.to);

  let valueMin = $state(Math.max(_scale.toValue(range.from || availableRange.from), availableValueMin));
  let valueMax = $state(Math.min(_scale.toValue(range.to || availableRange.to), availableValueMax));

  let zIndexMod = $state(0);
  let style = $derived(segments(valueMin, valueMax));

  function onUpdateMinValue(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      const unscaledFrom = _scale.fromValue(event.target.valueAsNumber);
      valueMin = _scale.toValue(unscaledFrom);
      range.from = unscaledFrom > availableRange.from ? unscaledFrom : undefined;
    }
  }

  function onUpdateMaxValue(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      const unscaledTo = _scale.fromValue(event.target.valueAsNumber);
      valueMax = _scale.toValue(unscaledTo);
      range.to = unscaledTo < availableRange.to ? unscaledTo : undefined;
    }
  }

  function segments(valueMin: number, valueMax: number) {
    /*                                                      */
    /*                                                                */
    /*                                                                          */
    /*                   */
    const left = positiveOrZero(valueMin - availableValueMin).toPrecision(6);
    const middle = positiveOrZero(valueMax - valueMin).toPrecision(6);
    const right = positiveOrZero(availableValueMax - valueMax).toPrecision(6);
    const thumb = "calc(var(--thumb-size, 24px) /2)";
    return `grid-template-columns: ${left}fr ${thumb} ${middle}fr ${thumb} ${right}fr`;
  }

  function positiveOrZero(num: number): number {
    /*                                                                                         */
    /*                                                              */
    return num > 0 ? 10 * num : 0;
  }
</script>

<div class="hc_slider" {style} use:observe={_scale.resizeObserver}>
  <span class="hc_slider__track"></span>
  <input
    class="hc_slider__thumb"
    style="z-index: calc( 2 - {zIndexMod} )"
    type="range"
    min={availableValueMin}
    max={valueMax}
    bind:value={valueMin}
    {step}
    data-ts-feature-filter-method="slider_min"
    oninput={onUpdateMinValue}
    {...featureTrackingReferenceAttributes(featureTrackingReference)}
    onpointerdown={() => (zIndexMod = -1)}
  />
  <span class="hc_slider__track"></span>
  <input
    class="hc_slider__thumb"
    style="z-index: calc( 2 + {zIndexMod} )"
    type="range"
    min={valueMin}
    max={availableValueMax}
    bind:value={valueMax}
    {step}
    data-ts-feature-filter-method="slider_max"
    oninput={onUpdateMaxValue}
    {...featureTrackingReferenceAttributes(featureTrackingReference)}
    onpointerdown={() => (zIndexMod = 1)}
  />
  <span class="hc_slider__track"></span>
</div>

<style lang="scss">
  .hc_slider {
    --thumb-color: var(--oc-base-color-black-100);
    --thumb-size: var(--oc-base-dimension-relative-24);
    --track-color: var(--oc-base-color-gray-150);
    --track-height: var(--oc-base-dimension-2);
    --track-border-radius: calc(var(--track-height, 2px) / 2);

    padding: 0;
    width: 100%;
    display: grid;
    grid-template-rows: var(--thumb-size, 24px);
    align-items: center;

    &__thumb {
      &:first-of-type {
        grid-row: 1;
        grid-column: 1 / 5;
      }

      &:last-of-type {
        grid-row: 1;
        grid-column: 2 / 6;
      }
    }

    &__track {
      height: var(--track-height, 2px);
      grid-row: 1;

      &:first-of-type {
        background: var(--track-color, #cccccc);
        border-radius: var(--track-border-radius, 1px) 0 0 var(--track-border-radius, 1px);
        grid-column: 1 / 3;
      }

      &:not(:first-of-type, :last-of-type) {
        background: var(--thumb-color, #212121);
        grid-column: 3;
      }

      &:last-of-type {
        background: var(--track-color, #cccccc);
        border-radius: 0 var(--track-border-radius, 1px) var(--track-border-radius, 1px) 0;
        grid-column: 4 / 6;
      }
    }
  }

  /*                                                                        */
  /*                                               */
  @mixin track {
    height: var(--track-height, 2px);
  }

  @mixin thumb {
    background-color: var(--thumb-color, #212121);
    width: var(--thumb-size, 24px);
    height: var(--thumb-size, 24px);
    border-radius: 50%;
    transition: transform ease-in 100ms;
    pointer-events: all;
  }

  @mixin thumb-active {
    transform: scale(1.3);
  }

  @mixin thumb-focus {
    outline: var(--oc-semantic-focus-outline-color, #008cf8) var(--oc-semantic-focus-outline-style, solid)
      var(--oc-semantic-focus-outline-width, 2px);
    outline-offset: var(--oc-semantic-focus-outline-offset, 2px);
  }

  input[type="range"] {
    appearance: none;
    margin: 0;
    background: transparent;
    cursor: pointer;
    pointer-events: none;

    &:focus-visible {
      /*                                     */
      outline: none;
    }

    /*                    */
    /*              */
    &::-webkit-slider-runnable-track {
      appearance: none;
      @include track;
    }

    /*              */
    &::-webkit-slider-thumb {
      appearance: none;
      margin-top: calc(var(--track-height, 2px) / 2 - var(--thumb-size, 24px) / 2);
      @include thumb;
    }

    &:active::-webkit-slider-thumb {
      @include thumb-active;
    }

    /*               */
    &:focus-visible::-webkit-slider-thumb {
      @include thumb-focus;
    }

    /*                     */
    /*              */
    &::-moz-range-track {
      @include track;
    }

    /*              */
    &::-moz-range-thumb {
      border: none; /*                                    */
      @include thumb;
    }

    &:active::-moz-range-thumb {
      @include thumb-active;
    }

    /*               */
    &:focus-visible::-moz-range-thumb {
      @include thumb-focus;
    }
  }
</style>
